<template>
    <footer>
        <img src="@/assets/img/tenants/gondolaf.gif">
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 220px;
        background-image: url('../../../assets/img/tenants/waves.gif');
        background-size: cover;
        background-position-y: 50%;
        
        img {
            position: absolute;
            bottom: 35%;
            width: 30%;
            z-index: -1;
        }
    }

    @media screen and (max-width: 992px) {
        footer {
            display: none !important;
        }
    }
</style>