<template>
    <div class="container" :class="this.$route.name == 'EmailService' ? 'small' : ''">
        <ACTVHeader/>
        <div class="d-flex flex-wrap">
            <div class="col-12 col-lg-6 col-xl-4 offset-lg-3 offset-xl-4 auth-container">
                <Login v-if="this.$route.name == 'Login'"/>
                <Sign v-if="this.$route.name == 'Sign'"/>
                <EmailService v-if="this.$route.name == 'EmailService'"/>
                <ResetPassword v-if="this.$route.name == 'ResetPassword'"/>
            </div>
            <div class="col-lg-3 col-xl-4 col-offset-sx d-flex align-items-center">       
                <img src="@/assets/img/tenants/unica.png" class="w-100">
            </div>
        </div>
    </div>
    <ACTVFooter/>
</template>

<script>
import ACTVHeader from '../Header.vue'
import Login from '@/components/Login.vue'
import Sign from '@/components/Sign.vue'
import EmailService from '@/components/EmailService.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import ACTVFooter from '../Footer.vue'
export default {
    name: 'ACTVAuth',
    components: {
        ACTVHeader,
        Login,
        Sign,
        EmailService,
        ResetPassword,
        ACTVFooter
    }
}
</script>

<style lang="scss" scoped>
    .auth-container {
        background-color: #0868b4;
        border-radius: 25px;
    }

    // .col-offset-sx {
    //     background-image: url('../../../../assets/img/tenants/LineeLC.png');
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     padding: 40px;

    //     h1 {
    //         font-size: 65px;
    //         color: #2a4f9d;
    //         text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
    //     }
    // }

    @media screen and (max-width: 992px) {
        .col-offset-sx {
            display: none !important;
        }
    }
</style>