<template>
    <header class="d-flex flex-wrap"> 
        <div class="col-12 col-lg-3 py-2">
            <img src="@/assets/img/tenants/95a87b34-0cbf-11ec-b3b5-0800270bb7ac.svg" class="w-75">
        </div>
        <div class="p-4 col-3 offset-6 d-flex flex-wrap align-items-center">
            <div class="col-2">
                <a href="https://www.facebook.com/muoversivenezia" target="_blank">
                    <i class="fa-brands fa-facebook-f"></i>
                </a>
            </div>
            <div class="col-2">
                <a href="https://twitter.com/muoversivenezia" target="_blank">
                    <i class="fa-brands fa-twitter"></i>
                </a>
            </div>
            <div class="col-8 text-end">
                <a href="https://actv.avmspa.it/it" target="_blank">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

    i {
        font-size: 24px;
        color:  #0868b4;

        &.fa-magnifying-glass {
            color: #00ab5C;
            transform: rotate(90deg);
        }
    }

    @media screen and (max-width: 992px) {

        .col-lg-3 {
            text-align: center;
        }

        .offset-6 {
            display: none !important;
        }
    }
</style>